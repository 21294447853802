import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './CategoryManagementPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faTrash, faChevronDown, faChevronRight, faBars } from '@fortawesome/free-solid-svg-icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Snackbar, Alert } from '@mui/material';

function CategoryManagementPage() {
    const [kategorien, setKategorien] = useState([]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        axios.get('https://api.divingmaster.net/api/categories').then((response) => {
            const kategorienMitExpand = response.data.map((kategorie, index) => ({
                ...kategorie,
                isExpanded: false,
                index,
                unterkategorien: kategorie.unterkategorien.map((sub, subIndex) => ({
                    ...sub,
                    isExpanded: false,
                    subIndex,
                }))
            }));
            setKategorien(kategorienMitExpand);
        });
    }, []);

    const moveCategory = (dragIndex, hoverIndex) => {
        const newKategorien = [...kategorien];
        const draggedItem = newKategorien[dragIndex];
        newKategorien.splice(dragIndex, 1);
        newKategorien.splice(hoverIndex, 0, draggedItem);
        setKategorien(newKategorien);
    };

    const moveSubcategory = (catIndex, dragIndex, hoverIndex) => {
        const newKategorien = [...kategorien];
        const subcategories = newKategorien[catIndex].unterkategorien;
        const draggedItem = subcategories[dragIndex];
        subcategories.splice(dragIndex, 1);
        subcategories.splice(hoverIndex, 0, draggedItem);
        setKategorien(newKategorien);
    };

    const moveOption = (catIndex, subIndex, dragIndex, hoverIndex) => {
        const newKategorien = [...kategorien];
        const options = newKategorien[catIndex].unterkategorien[subIndex].optionen;
        const draggedItem = options[dragIndex];
        options.splice(dragIndex, 1);
        options.splice(hoverIndex, 0, draggedItem);
        setKategorien(newKategorien);
    };

    const handleCategoryNameChange = (index, event) => {
        const newKategorien = [...kategorien];
        newKategorien[index].name = event.target.value;
        setKategorien(newKategorien);
    };

    const handleAddCategory = () => {
        setKategorien([...kategorien, { name: '', unterkategorien: [], isExpanded: true }]);
    };

    const handleDeleteCategory = (index) => {
        const newKategorien = [...kategorien];
        newKategorien.splice(index, 1);
        setKategorien(newKategorien);
    };

    const toggleCategory = (index) => {
        const newKategorien = [...kategorien];
        newKategorien[index].isExpanded = !newKategorien[index].isExpanded;
        setKategorien(newKategorien);
    };

    const handleSubcategoryNameChange = (catIndex, subIndex, event) => {
        const newKategorien = [...kategorien];
        newKategorien[catIndex].unterkategorien[subIndex].name = event.target.value;
        setKategorien(newKategorien);
    };

    const handleAddSubcategory = (catIndex) => {
        const newKategorien = [...kategorien];
        newKategorien[catIndex].unterkategorien.push({ name: '', optionen: [], isExpanded: true });
        setKategorien(newKategorien);
    };

    const handleDeleteSubcategory = (catIndex, subIndex) => {
        const newKategorien = [...kategorien];
        newKategorien[catIndex].unterkategorien.splice(subIndex, 1);
        setKategorien(newKategorien);
    };

    const toggleSubcategory = (catIndex, subIndex) => {
        const newKategorien = [...kategorien];
        newKategorien[catIndex].unterkategorien[subIndex].isExpanded = !newKategorien[catIndex].unterkategorien[subIndex].isExpanded;
        setKategorien(newKategorien);
    };

    const handleOptionNameChange = (catIndex, subIndex, optIndex, event) => {
        const newKategorien = [...kategorien];
        newKategorien[catIndex].unterkategorien[subIndex].optionen[optIndex].name = event.target.value;
        setKategorien(newKategorien);
    };

    const handleAddOption = (catIndex, subIndex) => {
        const newKategorien = [...kategorien];
        newKategorien[catIndex].unterkategorien[subIndex].optionen.push({ name: '' });
        setKategorien(newKategorien);
    };

    const handleDeleteOption = (catIndex, subIndex, optIndex) => {
        const newKategorien = [...kategorien];
        newKategorien[catIndex].unterkategorien[subIndex].optionen.splice(optIndex, 1);
        setKategorien(newKategorien);
    };

    const handleSave = () => {
        const kategorienZumSpeichern = kategorien.map(kategorie => {
            const unterkategorien = kategorie.unterkategorien.map(sub => ({
                name: sub.name,
                optionen: sub.optionen
            }));
            return {
                name: kategorie.name,
                unterkategorien: unterkategorien
            };
        });
        axios.post('https://api.divingmaster.net/api/categories/update', kategorienZumSpeichern)
            .then(response => {
                setSnackbarMessage('Kategorien erfolgreich gespeichert.');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
            })
            .catch(error => {
                console.error('Fehler beim Speichern der Kategorien:', error);
                setSnackbarMessage('Fehler beim Speichern der Kategorien.');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="category-management-container">
                <h1>Kategorien verwalten</h1>
                <button className="add-button" onClick={handleAddCategory}>
                    <FontAwesomeIcon icon={faPlus} /> Kategorie hinzufügen
                </button>
                {kategorien.map((category, catIndex) => (
                    <CategoryItem
                        key={catIndex}
                        category={category}
                        index={catIndex}
                        moveCategory={moveCategory}
                        handleCategoryNameChange={handleCategoryNameChange}
                        handleDeleteCategory={handleDeleteCategory}
                        toggleCategory={toggleCategory}
                        handleAddSubcategory={handleAddSubcategory}
                        handleSubcategoryNameChange={handleSubcategoryNameChange}
                        handleDeleteSubcategory={handleDeleteSubcategory}
                        toggleSubcategory={toggleSubcategory}
                        moveSubcategory={moveSubcategory}
                        handleAddOption={handleAddOption}
                        handleOptionNameChange={handleOptionNameChange}
                        handleDeleteOption={handleDeleteOption}
                        moveOption={moveOption}
                    />
                ))}
                <button className="save-button" onClick={handleSave}>
                    <FontAwesomeIcon icon={faSave} /> Speichern
                </button>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </div>
        </DndProvider>
    );
}

function CategoryItem({ category, index, moveCategory, handleCategoryNameChange, handleDeleteCategory, toggleCategory, handleAddSubcategory, ...props }) {
    const ref = useRef(null);

    const [{ isOver }, drop] = useDrop({
        accept: 'category',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCategory(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'category',
        item: { type: 'category', index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className={`category-item ${isDragging ? 'dragging' : ''} ${isOver ? 'hovered' : ''}`}
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            <div className="category-header">
                <span className="drag-handle">
                    <FontAwesomeIcon icon={faBars} />
                </span>
                <FontAwesomeIcon
                    icon={category.isExpanded ? faChevronDown : faChevronRight}
                    onClick={() => toggleCategory(index)}
                    className="toggle-icon"
                />
                <div className="input-group">
                    <input
                        type="text"
                        value={category.name}
                        onChange={(event) => handleCategoryNameChange(index, event)}
                        placeholder="Kategoriename"
                    />
                    <button className="delete-button" onClick={() => handleDeleteCategory(index)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            </div>
            {category.isExpanded && (
                <>
                    <button className="add-button sub-add-button" onClick={() => handleAddSubcategory(index)}>
                        <FontAwesomeIcon icon={faPlus} /> Unterkategorie hinzufügen
                    </button>
                    {category.unterkategorien.map((sub, subIndex) => (
                        <SubcategoryItem
                            key={subIndex}
                            subcategory={sub}
                            catIndex={index}
                            index={subIndex}
                            moveSubcategory={props.moveSubcategory}
                            handleSubcategoryNameChange={props.handleSubcategoryNameChange}
                            handleDeleteSubcategory={props.handleDeleteSubcategory}
                            toggleSubcategory={props.toggleSubcategory}
                            handleAddOption={props.handleAddOption}
                            handleOptionNameChange={props.handleOptionNameChange}
                            handleDeleteOption={props.handleDeleteOption}
                            moveOption={props.moveOption}
                        />
                    ))}
                </>
            )}
        </div>
    );
}

function SubcategoryItem({ subcategory, catIndex, index, moveSubcategory, handleSubcategoryNameChange, handleDeleteSubcategory, toggleSubcategory, ...props }) {
    const ref = useRef(null);

    const [{ isOver }, drop] = useDrop({
        accept: 'subcategory',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }

            if (item.catIndex !== catIndex) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveSubcategory(catIndex, dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'subcategory',
        item: { type: 'subcategory', index, catIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className={`subcategory-item ${isDragging ? 'dragging' : ''} ${isOver ? 'hovered' : ''}`}
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            <div className="subcategory-header">
                <span className="drag-handle">
                    <FontAwesomeIcon icon={faBars} />
                </span>
                <FontAwesomeIcon
                    icon={subcategory.isExpanded ? faChevronDown : faChevronRight}
                    onClick={() => toggleSubcategory(catIndex, index)}
                    className="toggle-icon"
                />
                <div className="input-group">
                    <input
                        type="text"
                        value={subcategory.name}
                        onChange={(event) => handleSubcategoryNameChange(catIndex, index, event)}
                        placeholder="Unterkategoriename"
                    />
                    <button className="delete-button" onClick={() => handleDeleteSubcategory(catIndex, index)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            </div>
            {subcategory.isExpanded && (
                <>
                    <button className="add-button option-add-button" onClick={() => props.handleAddOption(catIndex, index)}>
                        <FontAwesomeIcon icon={faPlus} /> Option hinzufügen
                    </button>
                    {subcategory.optionen && subcategory.optionen.map((option, optIndex) => (
                        <OptionItem
                            key={optIndex}
                            option={option}
                            catIndex={catIndex}
                            subIndex={index}
                            index={optIndex}
                            handleOptionNameChange={props.handleOptionNameChange}
                            handleDeleteOption={props.handleDeleteOption}
                            moveOption={props.moveOption}
                        />
                    ))}
                </>
            )}
        </div>
    );
}

function OptionItem({ option, catIndex, subIndex, index, handleOptionNameChange, handleDeleteOption, moveOption }) {
    const ref = useRef(null);

    const [{ isOver }, drop] = useDrop({
        accept: 'option',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }

            if (item.catIndex !== catIndex || item.subIndex !== subIndex) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveOption(catIndex, subIndex, dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'option',
        item: { type: 'option', index, catIndex, subIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className={`option-item ${isDragging ? 'dragging' : ''} ${isOver ? 'hovered' : ''}`}
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            <div className="input-group">
                <span className="drag-handle">
                    <FontAwesomeIcon icon={faBars} />
                </span>
                <input
                    type="text"
                    value={option.name}
                    onChange={(event) => handleOptionNameChange(catIndex, subIndex, index, event)}
                    placeholder="Optionsname"
                />
                <button className="delete-button" onClick={() => handleDeleteOption(catIndex, subIndex, index)}>
                    <FontAwesomeIcon icon={faTrash} />
                </button>
            </div>
        </div>
    );
}

export default CategoryManagementPage;
