// src/components/ImageUploader.js
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'; // Icons importieren
import './ImageUploader.css';

function ImageUploader({ images, setImages }) {
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files).map(file => ({
            file,
            url: URL.createObjectURL(file),
            name: file.name
        }));
        setImages([...images, ...files]);
    };

    const removeImage = (index) => {
        setImages(images.filter((img, i) => i !== index));
    };

    // Funktion zum Einfügen aus der Zwischenablage
    const handlePaste = (e) => {
        const items = e.clipboardData.items;
        for (let item of items) {
            if (item.type.indexOf('image') !== -1) {
                const file = item.getAsFile();
                const newImage = {
                    file,
                    url: URL.createObjectURL(file),
                    name: file.name
                };
                setImages(prevImages => [...prevImages, newImage]);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('paste', handlePaste);
        return () => {
            window.removeEventListener('paste', handlePaste);
        };
    }, []);

    return (
        <div className="section">
            <label className="upload-label">Bilder hochladen:</label>

            <div className="custom-file-upload">
                <input
                    id="fileInput"
                    type="file"
                    multiple
                    onChange={handleImageChange}
                />
                <label htmlFor="fileInput">
                    <FontAwesomeIcon icon={faUpload} /> Dateien auswählen oder Ctrl+V zum Einfügen
                </label>
            </div>

            {images.length > 0 && (
                <div className="image-preview">
                    {images.map((image, index) => (
                        <div key={index} className="image-item">
                            <img
                                src={image.url}
                                alt={`Upload ${index}`}
                                className="preview-image"
                            />
                            <div className="image-buttons">
                                <button onClick={() => removeImage(index)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                            <p className="image-filename">{image.name}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ImageUploader;