import React, { useEffect } from 'react'; // React und useEffect importiert
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'; // Icons importieren
import './DynamicCheckboxes.css';

function DynamicCheckboxes({ kategorien, setKategorien, isFilter = false }) {
    useEffect(() => {
        if (!isFilter && kategorien.length === 0) {
            axios.get('https://api.divingmaster.net/api/categories').then((response) => {
                const categoriesWithExpanded = response.data.map(category => ({
                    ...category,
                    isExpanded: false,
                    unterkategorien: category.unterkategorien.map(sub => ({
                        ...sub,
                        isExpanded: false,
                        optionen: sub.optionen.map(option => ({
                            ...option,
                            isChecked: option.isChecked || false
                        }))
                    }))
                }));
                setKategorien(categoriesWithExpanded);
            });
        }
    }, [setKategorien, isFilter, kategorien]);

    const handleCategoryClick = (categoryIndex) => {
        const updatedKategorien = kategorien.map((category, idx) => {
            if (idx !== categoryIndex) return category;
            return { ...category, isExpanded: !category.isExpanded };
        });
        setKategorien(updatedKategorien);
    };

    const handleSubcategoryClick = (categoryIndex, subIndex) => {
        const updatedKategorien = kategorien.map((category, idx) => {
            if (idx !== categoryIndex) return category;
            const updatedSubcategories = category.unterkategorien.map((sub, sIdx) => {
                if (sIdx !== subIndex) return sub;
                return { ...sub, isExpanded: !sub.isExpanded };
            });
            return { ...category, unterkategorien: updatedSubcategories };
        });
        setKategorien(updatedKategorien);
    };

    const handleCheckboxChange = (categoryIndex, subIndex, optionIndex) => {
        const updatedKategorien = kategorien.map((category, idx) => {
            if (idx !== categoryIndex) return category;
            const updatedSubcategories = category.unterkategorien.map((sub, sIdx) => {
                if (sIdx !== subIndex) return sub;
                const updatedOptionen = sub.optionen.map((option, oIdx) => {
                    if (oIdx !== optionIndex) return option;
                    return { ...option, isChecked: !option.isChecked };
                });
                return { ...sub, optionen: updatedOptionen };
            });
            return { ...category, unterkategorien: updatedSubcategories };
        });
        setKategorien(updatedKategorien);
    };

    return (
        <div className="section">
            {kategorien.map((category, categoryIndex) => (
                <div key={categoryIndex} className="category">
                    <h2 onClick={() => handleCategoryClick(categoryIndex)}>
                        <FontAwesomeIcon icon={category.isExpanded ? faMinusSquare : faPlusSquare} /> {category.name}
                    </h2>
                    {category.isExpanded && category.unterkategorien.map((sub, subIndex) => (
                        <div key={subIndex} className="subcategory">
                            <h3 onClick={() => handleSubcategoryClick(categoryIndex, subIndex)}>
                                <FontAwesomeIcon icon={sub.isExpanded ? faMinusSquare : faPlusSquare} /> {sub.name}
                            </h3>
                            {sub.isExpanded && sub.optionen.map((option, optionIndex) => (
                                <div key={optionIndex} className="checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input
                                            type="checkbox"
                                            checked={option.isChecked}
                                            onChange={() => handleCheckboxChange(categoryIndex, subIndex, optionIndex)}
                                        />
                                        <span className="checkmark"></span>
                                        <span className="checkbox-label">{option.name}</span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default DynamicCheckboxes;