// src/components/TextFields.js
import React from 'react';

function TextFields({ name, setName, notes, setNotes }) {
    return (
        <div className="section">
            <label htmlFor="name">Name:</label>
            <input
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name eingeben"
            />

            <label htmlFor="notes">Notizen:</label>
            <textarea
                id="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Notizen hier eingeben..."
                rows="5"
            />
        </div>
    );
}

export default TextFields;
