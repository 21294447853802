// src/components/EntryForm.js
import React, { useState } from 'react';
import TextFields from './TextFields';
import ImageUploader from './ImageUploader';
import DynamicCheckboxes from './DynamicCheckboxes';
import SubmitButton from './SubmitButton';

function EntryForm() {
    const [name, setName] = useState('');
    const [notes, setNotes] = useState('');
    const [images, setImages] = useState([]);
    const [kategorien, setKategorien] = useState([]);

    const data = {
        name,
        notes,
        images,
        kategorien, // Ausgewählte Kategorien werden direkt aus 'kategorien' gesammelt
    };

    return (
        <div>
            <h1>Eintragsformular</h1>
            <TextFields name={name} setName={setName} notes={notes} setNotes={setNotes} />
            <ImageUploader images={images} setImages={setImages} />
            <DynamicCheckboxes kategorien={kategorien} setKategorien={setKategorien} />
            <SubmitButton data={data} />
        </div>
    );
}

export default EntryForm;