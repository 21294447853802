// src/components/EditEntryForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TextFields from './TextFields';
import DynamicCheckboxes from './DynamicCheckboxes';
import './ImageUploader.css'; // Stellen Sie sicher, dass das Styling importiert wird
import { Snackbar, Alert } from '@mui/material'; // Neu hinzugefügt

function EditEntryForm({ entry, onClose }) {
    const [name, setName] = useState('');
    const [notes, setNotes] = useState('');
    const [images, setImages] = useState([]);
    const [kategorien, setKategorien] = useState([]);

    const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar-Status
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        if (entry) {
            setName(entry.name);
            setNotes(entry.notes);
            // Setzen der Bilder
            const fetchedImages = entry.images.map((img) => ({
                file: null,
                url: `https://api.divingmaster.net/api/entries/${entry._id}/images/${img._id}`,
                name: img.name,
                id: img._id
            }));
            setImages(fetchedImages);

            // Laden der Kategorien aus der API
            axios.get('https://api.divingmaster.net/api/categories').then((response) => {
                const kategorienData = response.data;

                // Setzen von isChecked basierend auf entry.kategorien
                const kategorienMitChecked = kategorienData.map(category => {
                    const updatedUnterkategorien = category.unterkategorien.map(sub => {
                        const updatedOptionen = sub.optionen.map(option => {
                            const isChecked = entry.kategorien.some(entryKategorie =>
                                entryKategorie.category === category.name &&
                                entryKategorie.subcategory === sub.name &&
                                entryKategorie.option === option.name
                            );
                            return { ...option, isChecked };
                        });
                        return { ...sub, optionen: updatedOptionen };
                    });
                    return { ...category, unterkategorien: updatedUnterkategorien };
                });

                setKategorien(kategorienMitChecked);
            });
        }
    }, [entry]);

    // Definition von handleImageUpload
    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files).map(file => ({
            file,
            url: URL.createObjectURL(file),
            name: file.name,
            id: null // Neue Bilder haben noch keine ID
        }));
        setImages(prevImages => [...prevImages, ...files]);
    };

    const handleDeleteImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const handleSubmit = () => {
        const formData = new FormData();

        formData.append('name', name);
        formData.append('notes', notes);

        images.forEach((image) => {
            if (image.file) {
                formData.append('images', image.file);
            }
        });

        // Ermitteln der ausgewählten Optionen mit Kategorie und Unterkategorie
        const selectedOptions = [];
        kategorien.forEach(category => {
            category.unterkategorien.forEach(sub => {
                sub.optionen.forEach(option => {
                    if (option.isChecked) {
                        selectedOptions.push({
                            category: category.name,
                            subcategory: sub.name,
                            option: option.name
                        });
                    }
                });
            });
        });

        formData.append('kategorien', JSON.stringify(selectedOptions));

        axios
            .put(`https://api.divingmaster.net/api/entries/${entry._id}`, formData)
            .then((response) => {
                // alert('Eintrag erfolgreich aktualisiert');
                setSnackbarMessage('Eintrag erfolgreich aktualisiert.');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                onClose();
            })
            .catch((error) => {
                console.error('Fehler beim Aktualisieren des Eintrags:', error);
                setSnackbarMessage('Fehler beim Aktualisieren des Eintrags.');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            });
    };

    // Handler zum Schließen der Snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <div className="entry-form">
            <h1>Eintrag bearbeiten</h1>
            <TextFields name={name} setName={setName} notes={notes} setNotes={setNotes} />

            <div className="image-uploader">
                <h3>Bilder:</h3>
                {images.map((image, index) => (
                    <div key={index} className="image-item">
                        <img
                            src={image.url}
                            alt={image.name}
                            className="preview-image"
                        />
                        {/* Nur der Lösch-Button wird angezeigt */}
                        <div className="image-buttons">
                            <button onClick={() => handleDeleteImage(index)}>Löschen</button>
                        </div>
                        <p className="image-filename">{image.name}</p>
                    </div>
                ))}

                {/* Button zum Hinzufügen neuer Bilder mit angepasstem Styling */}
                <div className="custom-file-upload">
                    <input
                        id="fileInput"
                        type="file"
                        multiple
                        onChange={handleImageUpload}
                    />
                    <label htmlFor="fileInput">Dateien auswählen</label>
                </div>
            </div>

            <DynamicCheckboxes kategorien={kategorien} setKategorien={setKategorien} />

            <button onClick={handleSubmit}>Aktualisieren</button>

            {/* Snackbar-Komponente */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default EditEntryForm;