// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import EntryForm from './components/EntryForm';
import EditEntryForm from './components/EditEntryForm';
import EntryManagementPage from './components/EntryManagementPage';
import CategoryManagementPage from './components/CategoryManagementPage';

// MUI-Imports
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Drawer, Toolbar, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Inbox as InboxIcon, Mail as MailIcon, Build as BuildIcon } from '@mui/icons-material';

// Individuelles Theme erstellen
const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const drawerWidth = 250;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className="App" style={{ display: 'flex' }}>
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
          >
            <Toolbar>
              <BuildIcon sx={{ marginRight: 1 }} />
              <Typography variant="h6" noWrap component="div">
                Burda-Datenbank
              </Typography>
            </Toolbar>
            <List>
              <ListItem button component={Link} to="/">
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Eintragsformular" />
              </ListItem>
              <ListItem button component={Link} to="/entries">
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText primary="Einträge verwalten" />
              </ListItem>
              <ListItem button component={Link} to="/categories">
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Kategorien verwalten" />
              </ListItem>
            </List>
          </Drawer>
          <main
            style={{
              flexGrow: 0.2,
              width: 800,
              margin: '10px auto',
              position: 'relative',
            }}
          >
            <Toolbar />
            <Routes>
              <Route exact path="/" element={<EntryForm />} />
              <Route exact path="/entries" element={<EntryManagementPage />} />
              <Route path="/edit/:id" element={<EditEntryForm />} />
              <Route path="/categories" element={<CategoryManagementPage />} />
            </Routes>
          </main>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
